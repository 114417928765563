@import "bootstrap/index.scss";
@import "icons.scss";

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.dropdown-no-arrow {
  .dropdown-toggle::after {
    display: none;
  }
}
